import { MediaQuery, Palette, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import styled from "styled-components";

interface ContainerInterface {
  noMinHeight?: boolean;
  padding?: string | number;
}

const Container = styled.div<ContainerInterface>`
  background-color: ${Palette.Gray200};
  border-radius: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 0 0 40%;
  margin-bottom: ${Spacing.Spacing2};
  min-height: 30rem;
  overflow: hidden;
  padding: ${Spacing.Spacing2} ${Spacing.Spacing2} ${Spacing.Spacing2};
  text-align: center;

  ${({ noMinHeight }) => noMinHeight && `
    min-height: 0 !important;
  `}

  ${({ padding }) => padding && `
    padding: ${padding} !important;
  `}

  ${MediaQuery.LessThanMedium} {
    flex: 0 0 90%;
  }
`

export const CartStyled = {
  Container
}