import React, { FC, useEffect } from 'react';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import { CartStyled } from '../../domain/styles/Card.styled';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import IconTwitter from '@apg-stats/apg-core/lib/IconTwitter';
import IconDiscord from '@apg-stats/apg-core/lib/IconDiscord';
import { LoginStyled } from './Login.styled';
import { useLocation } from 'react-router-dom';
import { RequestAccount } from '../../proxy/general';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login: FC = () => {
  const query = useQuery();

  useEffect(() => {
    const redirectUrl = query.get("redirect");
    localStorage.setItem('redirectUrl', redirectUrl || '/');
  }, [])

  const loginTwitter = async () => {
    const twitterUrl = await RequestAccount.twitterUrl();
    window.location.href = twitterUrl;
  }

  const loginDiscord = async () => {
    const discordUrl = await RequestAccount.discordUrl();
    window.location.href = discordUrl;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100% - 9rem)' }}>
        <CartStyled.Container>
          <Title>Iniciar sesión</Title>
          <Paragraph>Inicia sesión para tener acceso a futuras mejoras en la plataforma. Y si eres creador de contenido o analista de alguno de estos juegos (WildRift, Valorant o R6), escríbenos por Twitter para que obtengas la versión PRO de forma gratuita.</Paragraph>

          <div className='d-flex gap-2 align-items-center justify-content-center flex-column'>
            <LoginStyled.LoginButton
              type="primary"
              shape="round"
              icon={<IconTwitter />} 
              size='large'
              onClick={() => loginTwitter()}
            >
              <span className='ms-2'>Iniciar sesión con Twitter</span>
            </LoginStyled.LoginButton>

            <LoginStyled.LoginButton
              className="btn-discord"
              type="primary"
              shape="round"
              icon={<IconDiscord />} 
              size='large'
              onClick={() => loginDiscord()}
            >
              <span className='ms-2'>Iniciar sesión con Discord</span>
            </LoginStyled.LoginButton>
          </div>
        </CartStyled.Container>
      </div>
      <ApgFooter copyright={'© 2019-2022'} />
    </>
  )
};

export default Login;
