import React, { FC, useEffect } from 'react';
import { Typography } from 'antd';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { CartStyled } from '../../domain/styles/Card.styled';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import IconTwitter from '@apg-stats/apg-core/lib/IconTwitter';

const { Link } = Typography;

const Account: FC = () => {
  const [cookies] = useCookies();
  const history = useHistory();
  
  useEffect(() => {
    if (!cookies.APGTOKEN) {
      history.push('/login')
    }
  }, [cookies]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100% - 9rem)' }}>
        <CartStyled.Container>
          <Title>Sesión iniciada</Title>
          <Paragraph>Ahora puedes navegar a alguna de nuestras areas para usuarios.</Paragraph>
          <ul>
            <li>Wildrift - <Link href='https://wildrift.apgstats.club'>https://wildrift.apgstats.club</Link></li>
            <li>APG Plus - <Link href='https://plus.apgstats.club'>https://plus.apgstats.club</Link></li>
          </ul>
        </CartStyled.Container>
      </div>
      <ApgFooter copyright={'© 2019-2022'} />
    </>
  )
};

export default Account;
