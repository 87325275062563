import { Champ, ChampFilters, ChampPaginate, ChampStat } from "@apg-stats/apg-core/lib/champs";
import { Creator } from "@apg-stats/apg-core/lib/creators";
import { ProBuild } from "@apg-stats/apg-core/lib/probuilds";
import { TournamentPaginate } from "@apg-stats/apg-core/lib/tournaments";
import { LaneItem } from "../domain/lanes";
import { TeamBase } from "../domain/team.interface";
import { TokenResponse } from "../domain/token.interface";

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const twitterUrl = async (): Promise<string> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/login/twitter/generate`, {
    method: 'POST'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const twitterAuth = async (): Promise<TokenResponse> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/login/twitter/auth`, {
    method: 'POST'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const discordUrl = async (): Promise<string> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/login/discord/generate`, {
    method: 'POST'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const discordAuth = async (code: string): Promise<TokenResponse> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/login/discord/auth?code=${code}`, {
    method: 'POST'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestAccount = {
  twitterUrl,
  twitterAuth,
  discordUrl,
  discordAuth
}