import { TokenResponse } from '../domain/token.interface';
import { RequestAccount } from '../proxy/general';
import { AppActions, DispatchApp } from './reducer';

const { IsFetching, FinishedFetching } = AppActions;

const userProfile = async (dispatchApp: DispatchApp): Promise<void> => {
  dispatchApp({ type: IsFetching });
  try {
    // return await RequestTools.getTools();
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const twitterUrl = async (dispatchApp: DispatchApp): Promise<string> => {
  dispatchApp({ type: IsFetching });
  try {
    return await RequestAccount.twitterUrl();
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const twitterAuth = async (dispatchApp: DispatchApp): Promise<TokenResponse> => {
  dispatchApp({ type: IsFetching });
  try {
    return await RequestAccount.twitterAuth();
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const discordUrl = async (dispatchApp: DispatchApp): Promise<string> => {
  dispatchApp({ type: IsFetching });
  try {
    return await RequestAccount.discordUrl();
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const discordAuth = async (dispatchApp: DispatchApp, code: string): Promise<TokenResponse> => {
  dispatchApp({ type: IsFetching });
  try {
    return await RequestAccount.discordAuth(code);
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

export const RequestGlobalApiActions = {
  userProfile,
  twitterUrl,
  twitterAuth,
  discordUrl,
  discordAuth
};
