import React, { FC, useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useGlobalAppDispatch, useGlobalAppState } from './store/app-context';
import { useCookies } from 'react-cookie';
import { AppActions } from './store/reducer';

import Spin from 'antd/lib/spin';
import Layout from 'antd/lib/layout';
import { Token, TokenUser } from './domain/token.interface';

import './App.scss';

import ApgToolbar from '@apg-stats/apg-core/lib/ApgToolbar';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import { BackTop } from 'antd';

import Account from './app/Account/Account';
import Login from './app/Login/Login';
import CallbackDiscord from './app/Login/Callbacks/Discord';

const App: FC = () => {
  const { pendingFetches, secondaryMenuLogo, showSecondaryMenu, secondaryMenuItems } = useGlobalAppState();
  const dispatchApp = useGlobalAppDispatch();
  const [cookies] = useCookies();
  const [isReady, setIsReady] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState<TokenUser>();

  const history = useHistory();
  const goToUrl = (path: string): void => {
    history.push(path);
  }

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const menuItems =[
    {
      id: '',
      title: 'Cuenta',
      href: '/'
    },
    {
      id: 'profile',
      title: 'Mi Perfil',
      href: '/profile',
      disabled: true
    }
  ];

  useEffect(() => {
    if (cookies.APGTOKEN) {
      const decodeToken: Token = jwt_decode(cookies.APGTOKEN);

      dispatchApp({ type: AppActions.SetUserToken, payload: decodeToken.user })
      setUser(decodeToken.user)
      setIsLogged(true)
    }

    setIsReady(true);
  }, [cookies]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {pendingFetches > 0 && (
        <div className="loading-container">
          <Spin />
        </div>
      )}
      <Layout style={{ backgroundColor: Palette.Gray100 }}>
        <BackTop />
        <ApgToolbar
          active={splitLocation[1]}
          showGameLogo={false}
          menuItems={menuItems}
          goToUrl={goToUrl}
          secondaryMenuLogo={secondaryMenuLogo}
          showSecondaryMenu={showSecondaryMenu}
          secondaryMenuItems={secondaryMenuItems.length > 0 ? secondaryMenuItems: []}
          isLogged={isLogged}
          user={user}
        />
        <Layout style={{ marginTop: '4rem', height: 'calc(100vh - 4rem)' }}>
          <Switch>
            <Route exact path="/">
              <Account />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/callback/discord">
              <CallbackDiscord />
            </Route>
            <Redirect from="*" to="/" />
          </Switch>
        </Layout>
      </Layout>
    </>
  )
};

export default App;
