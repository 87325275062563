import React, { FC, useEffect } from 'react';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { RequestGlobalApiActions } from '../../../store/actions';
import { useGlobalAppDispatch } from '../../../store/app-context';
import { useCookies } from 'react-cookie';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CallbackDiscord: FC = () => {
  const dispatchApp = useGlobalAppDispatch();
  const query = useQuery();
  const [cookies, setCookie] = useCookies(['APGTOKEN']);

  useEffect(() => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    const callbackDiscord = async () => {
      const code = query.get("code")
      const callback = await RequestGlobalApiActions.discordAuth(dispatchApp, code || '');

      setCookie('APGTOKEN', callback.token, { domain: '.apg.gg', path: '/' });
      window.location.href = redirectUrl || process.env.REACT_APP_APG_ACCOUNT || '/';
    }
    
    callbackDiscord();
  }, [])

  return (
    <Spin size="large" />
  )
};

export default CallbackDiscord;
