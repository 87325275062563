import produce, { Draft } from 'immer';
import { Dispatch } from 'react';
import { DispatchObject } from '@apg-stats/apg-core/lib/dispatch-object';
import { Champ } from '@apg-stats/apg-core/lib/champs';
import { Creator } from '@apg-stats/apg-core/lib/creators';
import { AppToolbarItemInterface } from '@apg-stats/apg-core/lib/ApgToolbar';
import { TeamBase } from '../domain/team.interface';

export type DispatchApp = Dispatch<DispatchObject<AppActions>>;

export enum AppActions {
  IsFetching = 'IS_FETCHING',
  FinishedFetching = 'FINISHED_FETCHING',
  ErrorFetching = 'ERROR_FETCHING',
  SessionExpired = 'SESSION_EXPIRED',
  SetUserProfile = 'SET_USER_PROFILE',
  SetUserToken = 'SET_USER_TOKEN',
  SetChamps = 'SET_CHAMPS',
  SetCreators = 'SET_CREATORS',
  SetTeams = 'SET_TEAMS',
  SetSecondaryMenuItems = 'SET_SECONDARY_MENU_ITEMS',
  ClearData = 'CLEAR_DATA'
}

export interface UserToken {
  _id: string,
  username: string,
  email: string,
  countryCode: string,
  role: string
}

export interface AppState {
  isFetching: boolean;
  pendingFetches: number;
  sessionExpired: boolean;
  userProfile: null;
  userToken: UserToken;
  champs: Champ[];
  creators: Creator[];
  teams: TeamBase[];
  secondaryMenuLogo: string;
  showSecondaryMenu: boolean;
  secondaryMenuItems: AppToolbarItemInterface[];
  error: null;
}

export const initialState: AppState = {
  isFetching: false,
  pendingFetches: 0,
  sessionExpired: false,
  userProfile: null,
  userToken: {
    _id: '',
    username: '',
    email: '',
    countryCode: '',
    role: ''
  },
  champs: [],
  creators: [],
  teams: [],
  secondaryMenuLogo: '',
  showSecondaryMenu: false,
  secondaryMenuItems: [],
  error: null,
};

export const appReducer = produce((draft: Draft<AppState>, { type, payload }: DispatchObject<AppActions>) => {
  switch (type) {
    case AppActions.IsFetching:
      draft.pendingFetches += 1;
      draft.isFetching = true;
      draft.error = null;
      break;
    case AppActions.FinishedFetching:
      draft.pendingFetches -= 1;
      draft.isFetching = false;
      break;
    case AppActions.ErrorFetching:
      draft.pendingFetches = 0;
      draft.isFetching = false;
      draft.error = payload;
      break;
    case AppActions.SessionExpired:
      draft.sessionExpired = true;
      break;
    case AppActions.SetUserProfile:
      draft.userProfile = payload;
      break;
    case AppActions.SetUserToken:
      draft.userToken = payload;
      break;
    case AppActions.SetChamps:
      draft.champs = payload;
      break;
    case AppActions.SetCreators:
      draft.creators = payload;
      break;
    case AppActions.SetTeams:
      draft.teams = payload;
      break;
    case AppActions.SetSecondaryMenuItems:
      draft.secondaryMenuLogo = payload.secondaryMenuLogo as string;
      draft.showSecondaryMenu = payload.showSecondaryMenu as boolean;
      draft.secondaryMenuItems = payload.secondaryMenuItems as AppToolbarItemInterface[];
      break;
    case AppActions.ClearData:
      return initialState;
    default:
      throw new Error(`Unknown global action type: ${type}`);
  }
});
